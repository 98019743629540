export const UI_URLS = {
  WELCOME_LINK: '/',
  SIGNIN_LINK: '/signin/',
  SIGNUP_LINK: '/signup/',
  RESET_PASSWORD_LINK: '/reset_password/',
  RESET_PASSWORD_CONFIRM_LINK: '/reset_password/confirm/:uid/:token/',
  POLICY_LINK: '/privacy_policy/',
  VERIFY_EMAIL_LINK: '/verify_email/:key/',
  RESEND_EMAIL_LINK: '/resend_email/',
  RESEND_EMAIL_CONFIRM_LINK: '/resend_email/',
  PRIVACY_POLICY_EN_LINK: '/privacy_policy_en/',
  ACCOUNT_DELETE_LINK: '/accounts/delete/',
  FAQ_LINK: '/faq/',
  FAQ_DEVICES_LINK: '/faq/devices/',
  FAQ_SETTINGS_LINK: '/faq/settings/',
  FAQ_FUNCTIONS_LINK: '/faq/functions/',
  FAQ_GEOLOCATIONS_LINK: '/faq/geolocations/',

  OVERVIEW_LINK: '/',
  TASKS_LINK: '/tasks/',
  TASKS_CREATE_LINK: '/tasks/create/',
  TASK_LINK: '/task/:id/',
  SETTINGS_LINK: '/settings/',
};

export const API_ENDPOINTS = {
  // REGISTRATION AND AUTHENTICATION
  SIGNIN_ENDPOINT: '/accounts/login/',
  LOGOUT_ENDPOINT: '/accounts/logout/',
  SIGNUP_ENDPOINT: '/accounts/registration/',
  PASSWORD_RESET_ENDPOINT: '/accounts/password/reset/',
  PASSWORD_CHANGE_ENDPOINT: '/accounts/password/change/',
  RESEND_EMAIL_CONFIRM_ENDPOINT: '/accounts/registration/resend-email/',
  PASSWORD_RESET_CONFIRM_ENDPOINT: '/accounts/password/reset/confirm/',
  EMAIL_CONFIRM_ENDPOINT: '/accounts/confirm_email/',
  TOKEN_VERIFY_ENDPOINT: '/accounts/token/verify/',
  CHANGE_MIDDLENAME_ENDPOINT: '/users/change_middlename/',
  CITIES_ENDPOINT: '/cities/',
  GET_USER_ENDPOINT: '/users/get_user/',
  GET_USER_FINANCE_ENDPOINT: '/users/finance/',
  GET_USER_TASK_COUNT_ENDPOINT: '/users/count/',
  TOKEN_REFRESH_ENDPOINT: '/accounts/token/refresh/',

  // AUTHORIZARION USER ENDPOINTS
  GET_OPEN_TASKS_ENDPOINT: '/tasks/?search=open&source=web',
  GET_OPEN_TASK_ENDPOINT: '/tasks/',
  GET_NAVIGATION_TASK_ENDPOINT: '/tasks/get_task_navigation/',
  GET_COMPLETED_TASKS_ENDPOINT: '/tasks/?search=completed&source=web',
  GET_CARSHARING_ENDPOINT: '/carsharing/',
  GET_TYPES_BY_CARSHARING_ENDPOINT: '/tasks/get_type_list/',
  GET_CARS_BY_CARSHARING_ENDPOINT: '/cars/',
  GET_FIELD_LIST_BY_CARSHARING_AND_TYPE_ENDPOINT: '/tasks/get_options_by_task/',
  GET_FIELD_LIST_TO_DRAWER_ENDPOINT: '/tasks/get_field_to_drawer/',
};
