import * as React from 'react';
import { LuUserCircle2 } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { alpha, Box, Button, Chip, Container, Modal, Skeleton, TextField, Typography, useTheme } from '@mui/material';
import {
  useGetCountQuery,
  useGetProfileQuery,
  useGetFinanceQuery,
  useLogoutMutation,
  useChangeMiddleNameMutation,
} from '../../redux/services/auth';
import { IMiddleNameInputs } from '../types';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { namesValidation } from '../form-validation';
import { LoadingButton } from '@mui/lab';

const TASK_CARD_HEIGHT = 32;

interface IOverviewProps {
  setBadge: React.Dispatch<React.SetStateAction<number>>;
}

const OverviewComponent: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const count = useGetCountQuery();
  const finance = useGetFinanceQuery();
  const profile = useGetProfileQuery();
  const [changeMiddleName, changeMiddleNameResult] = useChangeMiddleNameMutation();
  const [isMiddleNameVisible, setIsMiddleNameVisible] = React.useState(false);
  const [logoutUser, logoutUserResult] = useLogoutMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMiddleNameInputs>({
    defaultValues: {
      middle_name: '',
    },
  });

  React.useEffect(() => {
    document.title = 'Обзор | КлинОк';
  }, []);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IMiddleNameInputs> = (data) => {
    handleChangeMiddleName(data);
  };

  const handleChangeMiddleName = async (data: IMiddleNameInputs) => {
    try {
      await changeMiddleName(data)
        .unwrap()
        .then((response) => {
          toast.success('Отчество успешно добавлено');
        });
      profile.refetch();
      setIsMiddleNameVisible(false);
    } catch (err) {
      toast.error('Произошла ошибка');
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      navigate('/');
    } catch (err) {}
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ flexGrow: 1, pb: 8 }}>
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LuUserCircle2 size={128} color={`${theme.palette.text.primary}`} />
        {profile.isLoading ? (
          <Skeleton variant="rounded" animation="wave" width={'100%'} height={`28px`} sx={{ mt: 1 }} />
        ) : (
          <Typography variant="h5" component="span" sx={{ mt: 1 }}>
            {profile.data?.last_name}
          </Typography>
        )}
        {profile.isLoading ? (
          <Skeleton variant="rounded" animation="wave" width={'100%'} height={`28px`} sx={{ mt: 1 }} />
        ) : (
          <Typography variant="h5" component="span">
            {profile.data?.first_name}
          </Typography>
        )}
        {profile.isLoading ? (
          <Skeleton variant="rounded" animation="wave" width={'100%'} height={`28px`} sx={{ mt: 1 }} />
        ) : (
          <Typography variant="h5" component="span">
            {profile.data?.middle_name}
          </Typography>
        )}
        <Box
          sx={{
            mt: 3,
            mb: 3,
            borderRadius: '10px',
            bgcolor: `${alpha(theme.palette.text.primary, theme.palette.mode === 'light' ? 0.11 : 0.13)}`,
            width: '100%',
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              телефон
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {profile.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                profile.data?.phone
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              почта
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {profile.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                profile.data?.email
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              город
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {profile.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                profile.data?.city
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span" sx={{ mb: 1 }}>
              тип пользователя
            </Typography>
            <Box sx={{ minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {profile.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                profile.data?.type.map((item, iter) => (
                  <Chip key={iter} label={item} sx={{ width: '27%', mb: 1, ml: 1 }} />
                ))
              )}
            </Box>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              завершенных задач
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {count.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                count.data?.count
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              отчёт за день
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {finance.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                finance.data?.finances_day
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              первая половина месяца
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {finance.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                finance.data?.finances_month_first
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}
          >
            <Typography variant="inherit" component="span">
              вторая половина месяца
            </Typography>
            <Typography variant="h6" component="span" sx={{ ml: 1, minHeight: `${TASK_CARD_HEIGHT}px` }}>
              {finance.isLoading ? (
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={`${TASK_CARD_HEIGHT}px`} />
              ) : (
                finance.data?.finances_month_second
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
            }}
          >
            <Typography variant="inherit" component="span">
              действия
            </Typography>
            <Box>
              {profile.data?.middle_name === '' && (
                <Button onClick={() => setIsMiddleNameVisible((prevState) => !prevState)} sx={{ ml: 1, mr: 1 }}>
                  Добавить отчество
                </Button>
              )}
              <Button onClick={() => handleLogout()} sx={{ ml: 0, mr: 1 }}>
                Выйти
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={isMiddleNameVisible}
        onClose={() => setIsMiddleNameVisible(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95%',
            maxWidth: '552px',
            bgcolor: 'background.paper',
            border: 'none',
            borderRadius: '8px',
            boxShadow: 24,
            p: 2,
            pt: 1,
            pb: 1,
          }}
        >
          <h3 id="modal-title" style={{ textAlign: 'center' }}>
            Добавить отчество
          </h3>
          <p id="modal-description" style={{ textAlign: 'center' }}>
            Вы можете ввести ваше отчество в поле ниже
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="middle_name"
              control={control}
              rules={namesValidation}
              render={({ field }) => (
                <TextField
                  label="Отчество"
                  margin="normal"
                  fullWidth
                  size="small"
                  autoComplete="middle-name"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  error={!!errors.middle_name?.message}
                  helperText={errors.middle_name?.message}
                />
              )}
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '40px' }}>
                <Button onClick={() => setIsMiddleNameVisible(false)} sx={{ mr: 2 }}>
                  Отменить
                </Button>
                <LoadingButton type="submit" loading={changeMiddleNameResult.isLoading} sx={{ ml: 2 }}>
                  Обновить
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </Container>
  );
};

export default OverviewComponent;
