import * as React from 'react';
import { Box, Breadcrumbs, Container, Link, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { UI_URLS } from '../../config';
import { emailValidation } from '../form-validation';
import { IEmailData } from '../types';
import { useResetPasswordMutation } from '../../redux/services/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { isErrorWithData } from '../is-error-with-message';

export const ResetPasswordComponent = () => {
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IEmailData>({
    defaultValues: {
      email: '',
    },
  });

  React.useEffect(() => {
    document.title = 'Сброс пароля | КлинОк';
  }, []);

  const onSubmit: SubmitHandler<IEmailData> = (data) => {
    resetPasswordQuery(data);
  };

  const resetPasswordQuery = async (data: IEmailData) => {
    try {
      await resetPassword(data)
        .unwrap()
        .then((response) => {
          toast.success(response.detail);
        });
    } catch (err) {
      const error = isErrorWithData(err);

      if (error) {
        if (err.data.non_field_errors) {
          toast.error(err.data.non_field_errors.toString());
        } else {
          toast.error('Произошла ошибка');
        }
      } else {
        toast.error('Неизвестная ошибка');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.SIGNIN_LINK}>
            Вход
          </Link>
          <Typography color="text.primary">Сброс пароля</Typography>
        </Breadcrumbs>
        <Typography color="text.primary" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
          Введите адрес электронной почты, и мы вышлем вам письмо для сброса пароля.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={emailValidation}
            render={({ field }) => (
              <TextField
                label="Почта"
                margin="normal"
                size="small"
                fullWidth
                autoComplete="email"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            )}
          />
          <LoadingButton
            type="submit"
            loading={resetPasswordResult.isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Сбросить пароль
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};
