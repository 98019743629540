import isEmail from 'validator/lib/isEmail';

const REQUIRED_FIELD = 'Обязательно для заполнения';

export const namesValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string) => {
    if (value.match(/[a-zA-z]/)) {
      return 'Поле не может содержать английские буквы';
    }
    if (value[0].match(/[а-яё]/)) {
      return 'Поле должно начинаться с заглавной буквы';
    }
    if (value.length < 2) {
      return 'Поле должно быть длиннее 2-ух символов';
    }
    if (value.length >= 32) {
      return 'Поле должно быть короче 32-ух символов';
    }
    if (value === value.toUpperCase()) {
      return 'Поле не должно быть в верхнем регистре';
    }
    return true;
  },
};

export const phoneValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string) => {
    if (value.length !== 11) {
      return 'Поле должно содержать 11 символов';
    }
    if (!value.match(/^79\d{9}/)) {
      return 'Формат поля должен быть: 79XXXXXXXXX';
    }
    return true;
  },
};

export const cityValidation = {
  required: REQUIRED_FIELD,
};

export const typeValidation = {
  required: REQUIRED_FIELD,
};

export const emailValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string) => {
    if (!isEmail(value)) {
      return 'Проверьте почту и попробуйте снова';
    }
    return true;
  },
};

export const passwordValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string) => {
    if (value.match(/[а-яёА-ЯЁ]/)) {
      return 'Пароль не может содержать русские буквы';
    }
    if (value.length < 8) {
      return 'Пароль должен быть длиннее 8-ми символов';
    }
    if (value.length >= 32) {
      return 'Пароль должен быть короче 32-ух символов';
    }
    return true;
  },
};

// Create Task Validataion
export const requiredValidation = {
  required: REQUIRED_FIELD,
};

export const nonRequiredValidation = {
  required: false,
};

export const vinValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string | undefined) => {
    if (typeof value === 'undefined') {
      return 'Номер VIN должен существовать';
    }
    if (value.length !== 7) {
      return `Номер VIN должен содержать последние 7 символов (${value.length})`;
    }
    return true;
  },
};

export const carPlateValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string | undefined) => {
    if (typeof value === 'undefined') {
      return 'Номер автомобиля должен существовать';
    }
    if (!value.match(/[АВЕКМНОРСТУХABEKMHOPCTYX]{1}[0-9]{3}[АВЕКМНОРСТУХABEKMHOPCTYX]{2}[0-9]{2,3}/i)) {
      return 'Номер автомобиля должен быть правильного формата';
    }
    if (value.length > 9) {
      return 'Номер автомобиля должен содержать 8 или 9 символов';
    }
    return true;
  },
};
