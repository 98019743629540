import * as React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import welcome from '../../assets/icons/welcome.png';
import { UI_URLS } from '../../config';

const WelcomeComponent = () => {
  React.useEffect(() => {
    document.title = 'Главная | КлинОк';
  }, []);

  return (
    <Container
      component="div"
      maxWidth="xs"
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 2 }}
    >
      <Box
        sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <img src={welcome} style={{ width: '50%' }} />
        <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mt: 3 }}>
          Сервис для водителей компании
        </Typography>
      </Box>
      <Button component={RouterLink} to={UI_URLS.SIGNIN_LINK} variant="contained" fullWidth size="large">
        Начать
      </Button>
    </Container>
  );
};

export default WelcomeComponent;
