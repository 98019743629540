import * as React from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import Button from '@mui/material/Button';
import { RxComponentNone, RxIdCard } from 'react-icons/rx';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { UI_URLS } from '../config';
import { TbCircleLetterM } from 'react-icons/tb';
import {
  LuCar,
  LuChevronLeft,
  LuComponent,
  LuMenu,
  LuTimer,
  LuTimerOff,
  LuTrash,
  LuType,
  LuUserCircle2,
} from 'react-icons/lu';
import { TTask } from './types';
import { useLazyGetFieldToDrawerTaskQuery, usePostDeleteTaskMutation } from '../redux/services/tasks';
import { MdNumbers } from 'react-icons/md';
import { toast } from 'react-toastify';

interface IHeaderType {
  type: 'unauthorized' | 'authorized' | 'loading';
  taskInformation?: TTask;
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const HeaderComponent: React.FC<IHeaderType> = ({ type, taskInformation }) => {
  const navigate = useNavigate();
  const [id, setId] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [deleteTask, deleteTaskResult] = usePostDeleteTaskMutation();
  const [drawerField, drawerFieldResult] = useLazyGetFieldToDrawerTaskQuery();
  const [state, setState] = React.useState({
    bottom: false,
  });

  React.useEffect(() => {
    if (state.bottom === true && id) {
      drawerField({ carsharing: taskInformation?.carsharing, task_type: taskInformation?.type, id: id });
    }
    return () => {};
  }, [state]);

  React.useEffect(() => {
    if (window.location.pathname.includes('/task/')) {
      setVisible(false);
      setId(window.location.pathname.split('/task/')[1]);
    } else {
      setVisible(true);
    }
  }, [window.location.pathname]);

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const getIcon = (key: string) => {
    if (key === 'id') {
      return <MdNumbers size={22} />;
    } else if (key === 'car_model') {
      return <LuCar size={22} />;
    } else if (key === 'carsharing') {
      return <LuComponent size={22} />;
    } else if (key === 'created_at') {
      return <LuTimer size={22} />;
    } else if (key === 'finished_at') {
      return <LuTimerOff size={22} />;
    } else if (key === 'car_plate') {
      return <RxIdCard size={22} />;
    } else if (key === 'type_display') {
      return <LuType size={22} />;
    } else if (key === 'mkad_bool') {
      return <TbCircleLetterM size={22} />;
    } else {
      return <RxComponentNone size={22} />;
    }
  };

  const getDatetimeFormatter = (datetime: string | undefined) => {
    if (typeof datetime === 'undefined') {
      return '';
    }
    const dt = new Date(datetime);

    const year = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const day = dt.getDate().toString().padStart(2, '0');
    const hour = dt.getHours().toString().padStart(2, '0');
    const minute = dt.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  const handleDelete = async () => {
    if (typeof taskInformation === 'undefined') {
      return;
    }
    toast
      .promise(deleteTask({ id: taskInformation.id }).unwrap(), {
        success: 'Задача успешно удалена 👌',
        error: 'Ошибка при удалении задачи 🤯',
      })
      .then(() => {
        navigate('/tasks/');
      });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {drawerFieldResult.isFetching ? (
        <LinearProgress />
      ) : (
        <>
          <List>
            {(
              Object.keys(drawerFieldResult?.data ? drawerFieldResult.data : []) as Array<
                keyof typeof drawerFieldResult.data
              >
            ).map(
              (key) =>
                key !== 'can_delete' && (
                  <ListItem key={key}>
                    <ListItemButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemIcon>{getIcon(key)}</ListItemIcon>
                      {key === 'created_at' || key === 'finished_at' ? (
                        <ListItemText primary={getDatetimeFormatter(taskInformation && taskInformation[key])} />
                      ) : (
                        <ListItemText primary={taskInformation && taskInformation[key]} />
                      )}
                    </ListItemButton>
                  </ListItem>
                ),
            )}
          </List>
          {drawerFieldResult.data?.can_delete && (
            <>
              <Divider />
              <List>
                <ListItem>
                  <ListItemButton onClick={handleDelete}>
                    <ListItemIcon>
                      <LuTrash />
                    </ListItemIcon>
                    <ListItemText primary={'Удалить'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          )}
        </>
      )}
    </Box>
  );

  const taskName = (): string => {
    if (taskInformation) {
      return `${taskInformation?.car_model.length >= 17 ? `${taskInformation?.car_model.slice(0, 14)}...` : taskInformation.car_model} [${taskInformation?.car_plate?.toLocaleUpperCase() || taskInformation?.vin_number?.toLocaleUpperCase()}]`;
    } else {
      return '';
    }
  };

  return visible ? (
    <AppBar position="static" elevation={0}>
      <Toolbar id="back-to-top-anchor">
        <Typography
          variant="h6"
          noWrap
          component={Link}
          to={UI_URLS.WELCOME_LINK}
          sx={{
            fontFamily: 'Comfortaa',
            color: 'inherit',
            fontSize: '16px',
            textDecoration: 'none',
            pl: 1,
          }}
        >
          КлинОк
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {type === 'unauthorized' && (
          <>
            <Button component={Link} color="inherit" to={UI_URLS.SIGNIN_LINK}>
              Вход
            </Button>
            <Button component={Link} color="inherit" to={UI_URLS.SIGNUP_LINK}>
              Регистрация
            </Button>
          </>
        )}
        {type === 'authorized' && (
          <>
            <Button component={Link} color="inherit" to={UI_URLS.OVERVIEW_LINK}>
              <LuUserCircle2 size={26} />
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  ) : (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar
          id="back-to-top-anchor"
          sx={{
            pl: 1,
          }}
        >
          <Button
            component={Link}
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate('/tasks/');
            }}
            color="inherit"
          >
            <LuChevronLeft size={26} />
          </Button>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                width: '100%',
                textAlign: 'center',
                fontFamily: 'Comfortaa',
                color: 'inherit',
                fontSize: '14px',
                textDecoration: 'none',
              }}
            >
              {taskName()}
            </Typography>
          </Box>
          <>
            <React.Fragment key={'bottom'}>
              <Button onClick={toggleDrawer('bottom', true)} color="inherit">
                <LuMenu size={26} />
              </Button>
              <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                {list('bottom')}
              </Drawer>
            </React.Fragment>
          </>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderComponent;
