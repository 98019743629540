import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Breadcrumbs, IconButton, InputAdornment, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { emailValidation, passwordValidation } from '../form-validation';
import { useLoginMutation } from '../../redux/services/auth';
import { UI_URLS } from '../../config';
import { ISigninInputs } from '../types';
import { toast } from 'react-toastify';
import { isErrorWithData } from '../is-error-with-message';

const SigninComponent: React.FC = () => {
  const navigate = useNavigate();
  const [loginUser, loginUserResult] = useLoginMutation();
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISigninInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  React.useEffect(() => {
    document.title = 'Вход | КлинОк';
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<ISigninInputs> = (data) => {
    signinQuery(data);
  };

  const signinQuery = async (data: ISigninInputs) => {
    try {
      await loginUser(data).unwrap();
      navigate('/');
    } catch (err) {
      const error = isErrorWithData(err);

      if (error) {
        if (err.data.non_field_errors) {
          toast.error(err.data.non_field_errors.toString());
        } else {
          toast.error('Произошла ошибка');
        }
      } else {
        toast.error('Неизвестная ошибка');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.WELCOME_LINK}>
            Главная
          </Link>
          <Typography color="text.primary">Вход</Typography>
        </Breadcrumbs>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={emailValidation}
            render={({ field }) => (
              <TextField
                label="Почта"
                margin="normal"
                fullWidth
                size="small"
                autoComplete="email"
                autoCapitalize="none"
                inputMode="email"
                autoCorrect="false"
                onChange={(e) => {
                  field.onChange(e);
                }}
                data-cy="email"
                value={field.value}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={passwordValidation}
            render={({ field }) => (
              <TextField
                label="Пароль"
                margin="normal"
                fullWidth
                size="small"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                onChange={(e) => {
                  field.onChange(e);
                }}
                data-cy="password"
                value={field.value}
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <LuEyeOff color="" /> : <LuEye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <LoadingButton
            type="submit"
            loading={loginUserResult.isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
            data-cy="submit"
          >
            Войти
          </LoadingButton>
        </form>
        <Grid container>
          <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1, mt: 1 }}>
            <Link to={UI_URLS.SIGNUP_LINK} variant="body2" component={RouterLink}>
              Зарегистрироваться
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
            <Link to={UI_URLS.RESET_PASSWORD_LINK} variant="body2" component={RouterLink}>
              Сбросить пароль
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
            <Link to={UI_URLS.RESEND_EMAIL_LINK} variant="body2" component={RouterLink}>
              Подтвердить почту
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
            <Link to={UI_URLS.FAQ_LINK} variant="body2" component={RouterLink}>
              Инструкция
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SigninComponent;
