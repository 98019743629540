import * as React from 'react';
import { Box, Breadcrumbs, Container, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { UI_URLS } from '../../config';
import { passwordValidation } from '../form-validation';
import { IResetPasswordConfirm, IResetPasswordData } from '../types';
import { useResetPasswordConfirmMutation } from '../../redux/services/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import PasswordInformationComponent from '../../components/password-info-component';
import { isErrorWithData } from '../is-error-with-message';
import { toast } from 'react-toastify';

const ResetPasswordConfirmComponent = () => {
  const { uid, token } = useParams();
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [resetPasswordConfirm, resetPasswordConfirmResult] = useResetPasswordConfirmMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPasswordConfirm>({
    defaultValues: {
      uid: uid,
      token: token,
      new_password1: '',
      new_password2: '',
    },
  });

  React.useEffect(() => {
    document.title = 'Сброс пароля | КлинОк';
  }, []);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IResetPasswordConfirm> = (data) => {
    resetPasswordConfirmQuery(data);
  };

  const resetPasswordConfirmQuery = async (data: IResetPasswordConfirm) => {
    try {
      await resetPasswordConfirm(data)
        .unwrap()
        .then((response) => {
          toast.success(response.detail);
        });
    } catch (err) {
      const error = isErrorWithData(err);

      if (error) {
        if (err.data.non_field_errors) {
          toast.error(err.data.non_field_errors.toString());
        } else if (err.data.new_password1) {
          toast.error(err.data.new_password1.toString());
        } else if (err.data.new_password2) {
          toast.error(err.data.new_password2.toString());
        } else {
          toast.error('Произошла ошибка');
        }
      } else {
        toast.error('Неизвестная ошибка');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.SIGNIN_LINK}>
            Вход
          </Link>
          <Typography color="text.primary">Сброс пароля</Typography>
        </Breadcrumbs>
        <Typography color="text.primary" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
          Введите ваш новый пароль. Он должен соответствовать требованиям, приведенным ниже.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="new_password1"
            control={control}
            rules={passwordValidation}
            render={({ field }) => (
              <TextField
                label="Пароль"
                margin="normal"
                fullWidth
                size="small"
                type={showPassword1 ? 'text' : 'password'}
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.new_password1?.message}
                helperText={errors.new_password1?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <LuEyeOff color="" /> : <LuEye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="new_password2"
            control={control}
            rules={passwordValidation}
            render={({ field }) => (
              <TextField
                label="Пароль"
                margin="normal"
                fullWidth
                size="small"
                type={showPassword2 ? 'text' : 'password'}
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.new_password2?.message}
                helperText={errors.new_password2?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <LuEyeOff color="" /> : <LuEye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <PasswordInformationComponent />
          <LoadingButton
            type="submit"
            loading={resetPasswordConfirmResult.isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Изменить пароль
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};

export default ResetPasswordConfirmComponent;
function isErrorWithPassword(err: unknown) {
  throw new Error('Function not implemented.');
}
