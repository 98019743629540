import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';

export const FAQComponent = () => {
  React.useEffect(() => {
    document.title = 'Инструкции | КлинОк';
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mt: 1 }}>
          Инструкции для приложения
        </Typography>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
          <>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Настройка работы в фоновом режиме на Samsung</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Если в фоновом режиме приложение закрывается или воспроизведение зависает, чаще всего это связано с
              настройками энергосбережения на смартфоне. Чтобы настроить работу в фоновом режиме, используйте инструкцию
              для вашей операционной системы
            </Typography>

            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 12 и выше</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car:
                  <br /> - Разрешения - Отключите «Приостановить работу в неактивный период»
                  <br /> - Батарея - Переключите на «Не ограниченно»
                  <br /> - Мобильные данные - Включите «Разрешение на фоновые данные» и «Использовать данные в режиме
                  экономии трафика»
                  <br />
                  3. Откройте Настройки - Обслуживание устройства - Батарея - Ограничения в фоновом режиме - Неспящие
                  приложения - Нажмите на плюс + в правом верхнем углу - Найдите и выберите приложение Clean Car -
                  нажмите Добавить
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 11</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car:
                  <br /> - Разрешения - Отключите «Отзывать разрешения, если приложение не используется»
                  <br /> - Батарея - Включите «Разрешение фоновой деятельности»
                  <br /> - Батарея - Оптимизация расхода - Нажмите на «Не оптимизированные» - выберите «Все» - Найдите
                  приложение Clean Car и нажмите на кнопку переключения напротив
                  <br /> - Мобильные данные - Включите «Разрешение на фоновые данные» и «Использовать данные в режиме
                  экономии трафика»
                  <br />
                  3. Откройте Настройки - Обслуживание устройства - Батарея - Ограничения в фоновом режиме - Неспящие
                  приложения - Нажмите на плюс + в правом верхнем углу - Найдите и выберите приложение Clean Car -
                  нажмите Добавить
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 10</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car:
                  <br /> - Мобильные данные - Включите «Разрешение на фоновые данные» и «Использовать данные в режиме
                  экономии трафика»
                  <br /> - Батарея - Включите «Разрешение фоновой деятельности»
                  <br /> - Батарея - Оптимизация расхода - Нажмите на «Не оптимизированные» - выберите «Все» - Найдите
                  приложение Clean Car и нажмите на кнопку переключения напротив
                  <br />
                  3. Откройте Настройки - Обслуживание устройства - Батарея - Мониторинг энергопотребления приложений -
                  Приложения не переход. в режим сна - Нажмите на плюс + в правом верхнем углу - Найдите и выберите
                  приложение Clean Car - нажмите Добавить
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 9</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car:
                  <br /> - Мобильные данные - Включите «Разрешение на фоновые данные» и «Использ.прилож.при эконом.траф»
                  <br /> - Батарея - Включите «Разрешение фоновой деятельности»
                  <br /> - Батарея - Оптимизация расхода - Нажмите на «Не оптимизированные» - выберите «Все» - Найдите
                  приложение Clean Car и нажмите на кнопку переключения напротив
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 8</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car:
                  <br /> - Мобильные данные - Включите «Разрешение на фоновые данные» и «Использ.прилож.при эконом.траф»
                  <br /> - Батарея - Включите «Разрешение фоновой деятельности»
                  <br /> - Батарея - Оптимизация расхода - Нажмите на «Не оптимизированные» - выберите «Все» - Найдите
                  приложение Clean Car и нажмите на кнопку переключения напротив
                  <br /> - Авто
                  <br /> - Авто
                  <br />
                  3. Откройте Настройки - Обслуживание устройства - Батарея - Список исключений - Добавить приложение -
                  Найдите и выберите приложение Clean Car - нажмите Добавить
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Настройка работы в фоновом режиме на Xiaomi</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Если в фоновом режиме приложение закрывается или воспроизведение зависает, чаще всего это связано с
              настройками энергосбережения на смартфоне. Чтобы настроить работу в фоновом режиме, используйте инструкцию
              для вашей операционной системы
            </Typography>

            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 12</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Все приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car: <br />
                  - Трафик - Включите «Мобильный интернет», «Wi-Fi» и «Фоновое подключение»
                  <br />
                  - Включите «Автозапуск»
                  <br />
                  - Разрешения приложений - Отключите «Удалять разрешения и освобождать место»
                  <br />
                  - Сетевые подключения - Выберите «Мобильный интернет» и «Wi-Fi» и нажмите ОК
                  <br />- Контроль активностей - Выберите «Нет ограничений»
                  <br />
                  Откройте Настройки - Батарея - Нажмите на шестерёнку в правом верхнем углу - В пункте «Очищать память»
                  выберите «Никогда»
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 10</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Все приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car: <br />
                  - Трафик - Включите «Мобильный интернет», «Wi-Fi» и «Фоновое подключение» <br />
                  - Включите «Автозапуск»
                  <br />
                  - Сетевые подключения - Выберите «Мобильный интернет» и «Wi-Fi»
                  <br />- Контроль активностей - Выберите «Нет ограничений» <br />
                  3. Откройте Настройки - Питание и производительность - Нажмите на шестерёнку в правом верхнем углу - В
                  пункте «Очищать память» выберите «Никогда»
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 9</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Все приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car: <br />
                  - Включите «Автозапуск»
                  <br />
                  - Сетевые подключения - Выберите «3G/4G» и «Wi-Fi»
                  <br />- Контроль активностей - Выберите «Нет ограничений»
                  <br />
                  3. Откройте Настройки - Питание и производительность - Нажмите на шестерёнку в правом верхнем углу - В
                  пункте «Очищать память» выберите «Никогда»
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 8</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Все приложения - Clean Car
                  <br />
                  2. В настройках приложения Clean Car: <br />
                  - Включите «Автозапуск»
                  <br />
                  - Сетевые подключения - Выберите «3G/4G» и «Wi-Fi»
                  <br />- Контроль активностей - Выберите «Нет ограничений»
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Настройка работы в фоновом режиме на OPPO, Realme, Vivo</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Если в фоновом режиме приложение закрывается или воспроизведение зависает, чаще всего это связано с
              настройками энергосбережения на смартфоне. Чтобы настроить работу в фоновом режиме, используйте инструкцию
              для вашей операционной системы
            </Typography>

            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 12 и выше</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Приложения - Управление приложениями - Clean Car
                  <br />
                  2. В настройках приложения Clean Car: <br />
                  - Разрешения - Отключите «Приостановить работу в неактивный период»
                  <br />
                  - Использование батареи - Включите «Разрешить приоритетное выполнение» и «Разрешить работу в фоновом
                  режиме»
                  <br />- Трафик данных - Включите «Использовать трафик в фоновом режиме»
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                <Typography>
                  <b>Android 11</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  1. Откройте Настройки - Управление приложениями - Список приложений - Clean Car
                  <br />
                  2. В настройках приложения Clean Car: <br />
                  - Сведения о расходе трафика - Включите «Использовать трафик в фоновом режиме»
                  <br />
                  - Использование батареи - Включите «Разрешить приоритетное выполнение», «Разрешить работу в фоновом
                  режиме» и «Разрешить автоматический запуск»
                  <br />- Разрешения - Отключите «Отзывать разрешения, если приложение не используется»
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Настройка работы в фоновом режиме на Huawei, Honor</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Если в фоновом режиме приложение закрывается или воспроизведение зависает, чаще всего это связано с
              настройками энергосбережения на смартфоне. Чтобы настроить работу в фоновом режиме, в настройках вашего
              смартфона:
              <br />
              1. В разделе Батарея - Отключите «Режим энергосбережения»
              <br />
              2. В разделе Батарея - Запуск приложений - Найдите приложение Clean Car и отключите кнопку переключения
              напротив <br />
              3. Во всплывающем окне включите «Автозапуск», «Косвенный запуск» и «Работу в фоновом режиме» и нажмите на
              кнопку ОК <br />
              4. В разделе Батарея - Другие настройки батареи - Включите «Подключение к сети, когда устройство в спящем
              режиме» <br />
              5. В разделе Мобильная сеть - Статистика трафика - Отключите «Умная экономия трафика» или включите кнопку
              переключения напротив приложения Clean Car
            </Typography>
          </>
        </Box>
      </Box>
    </Container>
  );
};
