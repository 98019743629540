import * as React from 'react';
import { Box, Breadcrumbs, Link } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import { UI_URLS } from '../../config';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEmailConfirmMutation } from '../../redux/services/auth';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { isErrorWithData } from '../is-error-with-message';

const VerifyEmailComponent: React.FC = () => {
  const { key } = useParams();
  const [emailConfirm, emailConfirmResult] = useEmailConfirmMutation();

  React.useEffect(() => {
    document.title = 'Подтверждение почты | КлинОк';
  }, []);

  const verifyEmailQuery = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await emailConfirm({ key: key! })
        .unwrap()
        .then((response) => {
          toast.success(response.detail);
        });
    } catch (err) {
      const error = isErrorWithData(err);

      if (error) {
        if (err.data.non_field_errors) {
          toast.error(err.data.non_field_errors.toString());
        } else {
          toast.error('Произошла ошибка');
        }
      } else {
        toast.error('Неизвестная ошибка');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.SIGNIN_LINK}>
            Вход
          </Link>
          <Typography color="text.primary">Подтверждение почты</Typography>
        </Breadcrumbs>
        <Typography color="text.primary" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
          Чтобы подтвердить адрес электронной почты, Вам необходимо нажать кнопку ниже.
        </Typography>
        <form onSubmit={verifyEmailQuery} style={{ width: '100% ' }}>
          <LoadingButton
            type="submit"
            loading={emailConfirmResult.isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Подтвердить почту
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};

export default VerifyEmailComponent;
